'use client';

import Image from 'next/image';
import Icon from '@/app/ui/icons/Icon';
import Button from '@/app/ui/nav/Button';
import { Dict } from '@/app/types/Dict';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { lilita } from '@/app/ui/font';
import { useNavigation } from '@/app/contexts/Navigation';
import { usePathname } from 'next/navigation';
import Link from 'next/link';
// import Marquee from 'react-fast-marquee';
// import marketUrl from '@/app/db/market';
import logo from './images/logo.webp';

const navigation = [
  {
    name: '$goin',
    refname: 'coin',
    activeRef: 'coin',
  },
  {
    name: 'info',
    refname: 'nextgen',
    activeRef: 'nextgen',
  },
  {
    name: 'game',
    refname: 'findway',
    activeRef: 'findway',
  },
  {
    name: 'roadmap',
    refname: 'roadmapSection',
    activeRef: 'roadmap',
  },
  {
    name: 'tokenomics',
    refname: 'tokenomics',
    activeRef: 'tokenomics',
  },
];

type AvailableLinks =
  | 'hero'
  | 'coin'
  | 'nextgen'
  | 'findway'
  | 'roadmap'
  | 'tokenomics';

export default function Navbar() {
  const { refs } = useNavigation();
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState<AvailableLinks>('hero');
  const pathname = usePathname();

  useEffect(() => {
    const sections: AvailableLinks[] = [
      'hero',
      'coin',
      'nextgen',
      'findway',
      'roadmap',
      'tokenomics',
    ];
    const createObserver = (section: AvailableLinks) =>
      new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setActive(section);
          }
        },
        { threshold: 0.8 },
      );
    const observers = sections.reduce(
      (acc, section) => ({ ...acc, [section]: createObserver(section) }),
      {},
    );
    const handleRefs = (
      refAction: (observer: IntersectionObserver, ref: HTMLDivElement) => void,
    ) =>
      sections.forEach((section) => {
        if (refs?.[section])
          refAction(
            observers[section as keyof typeof observers],
            refs[section],
          );
      });
    // Start observing
    handleRefs((observer, ref) => observer.observe(ref));
    // Clean-up function
    return () => {
      // Stop observing
      handleRefs((observer, ref) => observer.unobserve(ref));
    };
  }, [refs]);
  return (
    <>
      {/* <a
        target="_blank"
        href={marketUrl}
        className="fixed z-[51] w-full bg-[#2946C7]"
      >
        <div className="container mx-auto flex h-[46px] items-center justify-between px-4 text-pale-blue-gray lg:px-0">
          <div className="flex-shrink-0 pr-2 uppercase">
            public sale special:
          </div>
          <div className="relative flex-1 text-center uppercase">
            <div className="block w-3/4 lg:hidden">
              <Marquee pauseOnHover>
                <p className="mx-10 flex w-full">
                  $GOIN
                  <span className="ml-1 text-baby-blue">ON Fjord Foundry</span>
                </p>
                <p className="mx-10 flex justify-center">
                  <span className="mx-1 text-baby-blue">
                    Fjord Foundry EXCLUSIVE:
                  </span>
                  $GOIN AT public sale PRICES!
                </p>
              </Marquee>
            </div>
            <p className="first absolute left-0 top-2/4 hidden w-full -translate-y-2/4 lg:block">
              $GOIN <span className="text-baby-blue">ON Fjord Foundry</span>
            </p>
            <div className="second absolute left-0 top-2/4 hidden w-full -translate-y-2/4 lg:block">
              <p className="flex justify-center">
                <span className="mx-1 text-baby-blue">
                  Fjord Foundry EXCLUSIVE:
                </span>
                $GOIN AT public sale PRICES!
              </p>
            </div>
          </div>
          <div className="hidden uppercase text-transparent lg:flex">
            public sale special:
          </div>
        </div>
      </a> */}
      <nav className="fixed left-0 right-0 top-[46px] z-50 mx-auto mt-[10px] w-[80%] rounded-[24px] bg-[rgba(9,10,30,0.86)] px-[10px] backdrop-blur-[2.9px] lg:mt-0 lg:w-full lg:rounded-none lg:bg-[rgba(17,7,35,0.47)] lg:px-11 lg:py-[27px]">
        <div className="flex w-full justify-between px-4 py-3 lg:container lg:px-0 lg:py-0">
          <div className="flex items-center">
            {pathname === '/' ? (
              <button
                type="button"
                onClick={() => {
                  if (refs && refs.hero) {
                    refs.hero.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }
                }}
              >
                <Image
                  alt="logo"
                  src={logo}
                  width={162}
                  height={36}
                  className="hidden lg:block"
                />
                <div className="block lg:hidden">
                  <Icon name="smallLogo" width={52} height={33} />
                </div>
              </button>
            ) : (
              <Link href="/">
                <Image
                  alt="logo"
                  src={logo}
                  width={162}
                  height={36}
                  className="hidden lg:block"
                />
                <div className="block lg:hidden">
                  <Icon name="smallLogo" width={52} height={33} />
                </div>
              </Link>
            )}
          </div>
          <div className="hidden gap-[35px] text-lg uppercase lg:flex lg:items-center">
            {pathname === '/' &&
              navigation.map((item: Dict) => (
                <Button
                  onClick={() => {
                    if (refs) {
                      const ref: HTMLDivElement | null | undefined =
                        refs?.[item.refname as keyof typeof refs];
                      if (ref) {
                        ref.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }
                    }
                  }}
                  type="link"
                  key={item.name}
                  href="/"
                  className={clsx('', {
                    'text-vivid-orange hover:text-vivid-orange':
                      active === item.activeRef,
                    'text-pale-blue-gray hover:text-ice-blue':
                      active !== item.activeRef,
                  })}
                >
                  {item.name}
                </Button>
              ))}
            {pathname === '/' && (
              <a
                target="_blank"
                href="/pdf/gamster-whitepaper.pdf"
                className="text-pale-blue-gray hover:text-vivid-orange"
              >
                Whitepaper
              </a>
            )}
            <a
              target="_blank"
              href="/pdf/audit.pdf"
              className="text-pale-blue-gray hover:text-vivid-orange"
            >
              Audit
            </a>
            {/* <a
              target="_blank"
              href={marketUrl}
              className="ml-[27px] flex h-[44px] w-[202px] items-center justify-center rounded-[73px] border-2 border-baby-blue bg-midnight-blue uppercase text-baby-blue backdrop-blur-[2px]"
            >
              Buy Tokens
            </a> */}
          </div>
          {/* <a
            target="_blank"
            href={marketUrl}
            className="flex h-[44px] items-center rounded-[71px] bg-baby-blue px-[27px] uppercase text-[#060A28] lg:hidden"
          >
            Buy tokens
          </a> */}
          {pathname === '/' && (
            <div className="flex text-pale-mist lg:hidden">
              <button
                type="button"
                onClick={() => setOpen((prev) => !prev)}
                aria-label="Menu"
              >
                <Icon name="burger" width={30} height={17} />
              </button>
            </div>
          )}
        </div>
        <div
          className={clsx(
            'overflow-y-hidden px-[55px] text-center transition-all duration-500 lg:hidden',
            {
              'max-h-[465px]': open,
              'max-h-0': !open,
            },
          )}
        >
          {navigation.map((item, index) => (
            <button
              className={clsx(
                `${lilita.className} w-full border-t border-t-[rgba(106,138,170,0.50)] py-4 text-[18px] uppercase`,
                {
                  'mt-[34px]': index === 0,
                  'text-vivid-orange hover:text-vivid-orange':
                    active === item.refname,
                  'text-pale-blue-gray hover:text-ice-blue':
                    active !== item.refname,
                },
              )}
              key={item.name}
              type="button"
              onClick={() => {
                if (refs) {
                  const ref: HTMLDivElement | null | undefined =
                    refs?.[item.refname as keyof typeof refs];
                  if (ref) {
                    ref.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }
                }
                setOpen(false);
              }}
            >
              {item.name}
            </button>
          ))}
          <a
            target="_blank"
            href="/pdf/gamster-whitepaper.pdf"
            className={`${lilita.className} block w-full border-t border-[rgba(106,138,170,0.50)] py-4 text-[18px] uppercase text-pale-blue-gray`}
          >
            Whitepaper
          </a>
          <a
            target="_blank"
            href="/pdf/audit.pdf"
            className={`${lilita.className} mb-[34px] block w-full border-b border-t border-[rgba(106,138,170,0.50)] py-4 text-[18px] uppercase text-pale-blue-gray`}
          >
            Audit
          </a>
        </div>
      </nav>
    </>
  );
}
